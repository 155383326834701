import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text, Title as Heading } from "../typography/text.component";
export const Container = styled(FlexibleDiv)`
  background: #eeede7;
  padding: 2rem 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 86%;
  gap: 1rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const VerticalCardContainer = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* width: 32%; */
  gap: 2rem;
  @media (max-width: 850px) {
    /* width: 48%; */
  }
  @media (max-width: 800px) {
    /* width: 100%; */
    align-items:center;
  }
`;

export const Title = styled(Text)`
  font-family: "Lato";
  font-size: 1.7rem;
  line-height: 1.9rem;
   color: #222221;
  align-self: center;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
export const Description = styled(Heading)`
  font-family: "Lato";
  font-style: italic;
  font-weight:bold;
  font-size: 1.7rem;
  text-align:center;
  line-height: 1.9rem;
   color: #222221;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const CardImageWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  height: max-content;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 45vh;
  @media (max-width: 800px) {
    width: 80vw;
    height: 80vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 80vw;
  }
`;

