import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";

export const FooterContainer = styled(FlexibleDiv)`
  justify-content: center;
  align-items: center;
  background: #4a4f53;
  padding: 3rem 0;



  @media (max-width: 800px) {
    width: 100%;
    padding:3rem 2rem;
    margin-right: 0;
  }
`;

export const InnerContainer = styled(FlexibleDiv)`
  align-items: flex-start;
  padding: 0;
  width: 86%;

  @media (max-width: 1250px) {
    gap: 2rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0;
  }
`;
