import React from "react";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Text } from "../../typography/text.component";
import { Spacer } from "../../spacer/spacer.component";
import {
  BottomSectionWrapper,
  BottomTextSection,
  StyledLink,
} from "./bottomSection.styles";

export const BottomSection = () => {
  const links = [
    { link: "Privacy Policy" },
    { link: "Terms of Use" },
    { link: "Legal Disclosure" },
    { link: "Financial Information" },
    { link: "Sitemap" },
    { link: "Newsroom" },
    { link: "Careers" },
    { link: "Contact Us" },
  ];
  return (
    <BottomSectionWrapper flexDirection="column" paddingVertical="1.5em 1rem">
      <FlexibleDiv
        width="auto"
        paddingVertical="0"
        justifyContent="center"
        className="container"
      >
        {links.map(({ link }) => (
          <StyledLink>{link}</StyledLink>
        ))}
      </FlexibleDiv>
      <Spacer />
      <BottomTextSection>
        <Text color="white" fontSize={"0.9rem"}>
          © 2024 Save the Children
        </Text>
        <Text color="white" fontSize={"0.9rem"}>
          Save the Children Federation, Inc. is a 501(c)(3) organization. Gifts
          are deductible to the full extent allowable under IRS regulations.
        </Text>
      </BottomTextSection>
    </BottomSectionWrapper>
  );
};
