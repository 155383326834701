import React from "react";
import {
  Container,
  BigTitle,
  HorizontalCardContainer,
  CardImageWrapper,
  CardImage,
  BodyWrapper,
  Description,
  StyledLink,
} from "./featuredPartners.styles";
import CardImage1 from "../../assets/images/olivela.png";

export const FeaturedPartners = () => {
  return (
    <Container>
      <BigTitle>Featured Partner</BigTitle>
      <HorizontalCardContainer>
        <CardImageWrapper>
          <CardImage src={CardImage1} />
        </CardImageWrapper>
        <BodyWrapper>
          <Description>
            Save the Children is proud to partner with online retailer Olivela
            all year long. In celebration of our "Make Summer Fair" campaign,
            now through July 21, Olivela will donate 20% of net proceeds from
            every purchase, online and in-store at their Nantucket boutique, to
            support our US Programs. Together, we’re helping to ensure the most
            underserved children in rural America have the resources they need
            to have a healthy, strong foundation to thrive as learners and in
            life.
          </Description>
          <StyledLink>Learn More.</StyledLink>
        </BodyWrapper>
      </HorizontalCardContainer>
    </Container>
  );
};
