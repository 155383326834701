import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../../pages/index.jsx";
import WhoWeAre from "../../pages/whoWeAre/whoWeAre.js";
import WhatWeDo from "../../pages/whatWeDo/whatWeDo.js";
import MonthlySupport from "../../pages/monthlySupport/monthlySupport.js";
import WaysToHelp from "../../pages/waysToHelp/waysToHelp.js";
import Donate from "../../pages/donate/donate.js";
import OurPartner from "../../pages/ourPartners/ourPartners.page.js";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/whoWeAre" element={<WhoWeAre />} />
        <Route path="/whatWeDo" element={<WhatWeDo />} />
        <Route path="/waysToHelp" element={<WaysToHelp />} />
        <Route path="/monthlySupport" element={<MonthlySupport />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/ourPartners" element={<OurPartner />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's No Page like this!!!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
