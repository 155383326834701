import styled from "styled-components";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Link } from "react-router-dom";
import { Title } from "../../typography/text.component";

export const RightSectionWrapper = styled(FlexibleDiv)`
  width: 40%;
  min-width: max-content;
  /* background-color: red; */
  gap:1rem;

  @media (max-width: 800px) {
    width: 100%;
    min-width:100%;
  }
`;
export const Heading = styled(Title)`
  color: #c9cbcc;
  font-size: 1.3rem;
  line-height: 1.6rem;
  border-bottom: 2px solid #c9cbcc;
 
  
`;
export const StyledLink = styled(Link)`
  color: #c9cbcc;
  font-size: ${({ smallText }) => (smallText ? "0.9rem" : "1rem")};
  font-style: ${({ smallText }) => (smallText ? "italic" : "normal")};
  font-weight: bold;
  margin-top: 0.4rem;
  text-decoration: none;
  @media (max-width: 800px) {
   text-align:center;
  }
`;
