import React from "react";
import {
  Container,
  SecondContainer,SecondContainerWithBG,
  BigTitle,
  Styledtext,
  StyledLink,
} from "./fulfillingMission.styles";
import { Button } from "../button/button.component";import { useNavigate } from "react-router-dom";


export const FulfillingMission = () => {
  const navigation = useNavigate()
  return (
    <Container>
      <BigTitle>Fulfilling Our Mission</BigTitle>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        Over 100 years ago, <StyledLink>our founder</StyledLink>
        said, “Save the Children is often told
        <b style={{ margin: "0 0.3rem" }}>
          that its aims are impossible – that there has always been child
          suffering and there always will be. We know it’s impossible only if we
          make it so
        </b>
        . It’s impossible only if we refuse to attempt it.”
      </Styledtext>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        Through the decades, Save the Children has continued to work to{" "}
        <b style={{ margin: "0 0.3rem" }}> save children’s lives</b>, and that’s
        still <b style={{ margin: "0 0.3rem" }}> what we do today</b>. By
        supporting Save the Children, you're helping save children’s lives, too.
      </Styledtext>

      <Button
        btnText="Donate Now"
        borderRadius="5px"
        // background={"#009ca6"}
        fontSize={"1.4rem"}
        paddingVertical="0.7rem"
        paddingHorizontal={"3rem"}
         onClick={()=>{navigation("/donate")}}
      />
    </Container>
  );
};

export const WeDoWhatEverItTakes = () => {
  return (
    <SecondContainer>
      <BigTitle>We Do Whatever It Takes</BigTitle>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        Our pioneering programs address children's unique needs, giving them a
        healthy start in life, the opportunity to learn and protection from
        harm. In <StyledLink>the United States</StyledLink> and{" "}
        <StyledLink>around the world</StyledLink>, our work creates lasting
        change for children, their families and communities – ultimately,
        transforming the future we all share.
      </Styledtext>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        It's important to note that all our work intersects – helping a boy or
        girl go to school also protects them from dangers such as{" "}
        <StyledLink>child trafficking</StyledLink> and{" "}
        <StyledLink>early marriage</StyledLink>. Keeping children healthy from{" "}
        <StyledLink>disease</StyledLink> or{" "}
        <StyledLink>malnutrition</StyledLink> means their parents are more
        likely to avoid costly treatment and be better able to provide for their
        family.
      </Styledtext>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        Because <StyledLink>the climate crisis</StyledLink> impacts all aspects
        of children’s lives, so too does Save the Children’s work.
      </Styledtext>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        This work is only made possible by the ongoing generosity of our donors,
        whose valuable support is used in{" "}
        <StyledLink>the most cost-effective ways</StyledLink>.
      </Styledtext>
    </SecondContainer>
  );
};

export const WaysToHelpCard = () => {
  return (
    <Container paddingHorizontal={"3rem"}>
      <BigTitle>Ways to Help</BigTitle>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        If you’re wondering how to help children in need – please know that
        every little bit counts. A blanket. Clean water. Educational supplies
        for schools that have none. From America to Zimbabwe, Save the Children
        is there, helping one child at a time. But we can’t do it without you.
        Whether you give, volunteer or run a road race, your generosity means
        the world in the life of a child.
      </Styledtext>
    </Container>
  );
};

export const SecondWaysToHelpCard = () => {
  return (
    <SecondContainer background={"#fff"}>
      <BigTitle>
        There are so many reasons to make a monthly donation today
      </BigTitle>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        Right now, as a result of climate change, conflict and COVID, a global
        hunger and food insecurity crisis is ravaging more than 40 countries.
        And nearly 200 million children are living in lethal war zones,
        experiencing life-changing consequences. But through the dedicated
        support of monthly and recurring donors like you, Save the Children is
        on the front lines, doing whatever it takes to protect children in the
        U.S. and around the world.
      </Styledtext>
      <Styledtext>
        <b style={{ margin: "0 0.3rem" }}>
          Here are three reasons why becoming a monthly donor in 2024 is a great
          choice.
        </b>
      </Styledtext>
    </SecondContainer>
  );
};

export const SecondWaysToHelpCardDuplicate = ({bg}) => {
  return (
    <SecondContainer background={"#fff"} bg={bg}>
      <BigTitle>WORKING TOGETHER FOR CHILDREN</BigTitle>

      <Styledtext color="white" textAlign="left" resTextAlign="left">
        Our goals are ambitious and can only be met in partnership with others.
        We work with a range of partners that help us achieve change for
        children on a global scale.
      </Styledtext>
    </SecondContainer>
  );
};
export const SecondWaysToHelpCardWithBackground = () => {
  return (
    <SecondContainerWithBG>
      <BigTitle background>WHY PARTNER WITH SAVE THE CHILDREN?</BigTitle>

      <Styledtext
        background
        WithBG
        color="white"
        textAlign="left"
        resTextAlign="left"
      >
        Save the Children cultivates innovative and meaningful partnerships with
        organisations that share our belief that every child has the same
        rights, and that realising these rights is the key to their future. We
        focus on long-term, mutually beneficial partnerships that benefit from
        our global presence. Our commitment to learning and comprehensive
        reporting ensures that our partners know and understand the impact of
        our collaboration.
      </Styledtext>
    </SecondContainerWithBG>
  );
};
