import React, { useState, useRef /* useEffect */ } from "react";
import {
  FormSectionContainer,
  Title,
  BodySection,
  BodyText,
  Form,
  Wrapper,
  ButtonWrapper,
} from "./formSection.styles";
import { Input } from "../input/input.component";
import { Button } from "../button/button.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

export const FormSection = () => {
  const [full_name, setFull_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");

  const handleFull_name = (e) => setFull_name(e.target.value);
  const handleLast_name = (e) => setLast_name(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);

  const notify = () => {
    toast.success("Thank you for sending us your Message!", {
      position: "top-center",
    });

    // toast.onChange((payload) => {
    //   if (payload.status === "removed") {
    //     navigate("/success");
    //   }
    // });
    // toast("Thank you for joining the Waitlist!");

    // toast.error("Error Notification !", {
    //   position: toast.POSITION.TOP_LEFT,
    // });

    // toast.warn("Warning Notification !", {
    //   position: toast.POSITION.BOTTOM_LEFT,
    // });

    // toast.info("Info Notification !", {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });

    // toast("Custom Style Notification with css class!", {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    //   className: "foo-bar",
    // });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_bmwwapq", "template_gzl2ou3", form.current, {
        publicKey: "xSCZYvKfedHsD4s2V",
      })
      .then(
        (result) => {
          console.log("Result:", result.text, form.current);
          setFull_name("");
          setLast_name("");
          setEmail("");
          notify();
        },
        (error) => {
          console.log("Its not working", error.text);
        }
      );
  };

  return (
    <FormSectionContainer>
      <Title>Join us to learn how you can help make change for children.</Title>
      <BodySection>
        <Form ref={form} onSubmit={sendEmail}>
          <Wrapper>
            <Input
              label="*First Name"
              type="text"
              name="first_name"
              value={full_name}
              onChange={handleFull_name}
            />
          </Wrapper>
          <Wrapper>
            <Input
              label="*Last Name"
              name="last_name"
              type="text"
              value={last_name}
              onChange={handleLast_name}
            />
          </Wrapper>
          <Wrapper>
            <Input
              label="*Email Address"
              name="email"
              type="email"
              value={email}
              onChange={handleEmail}
            />
          </Wrapper>
          <ButtonWrapper>
            <Button btnText="Join Us" background={"#009ca6"} />
          </ButtonWrapper>
        </Form>
        <BodyText>
          By providing your email, you will receive email news of your
          generosity in action, including inspiring children's stories,
          emergency alerts and ways to get involved.
        </BodyText>
      </BodySection>
    </FormSectionContainer>
  );
};
