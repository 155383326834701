import React from "react";
import {
  Container,
  RightSection,
  BigTitle,
  Styledtext,
  LinksContainer,
  StyledLink,
  ChartImage,
  ChartWrapper,
} from "./financialEfficiancy.styles";
import chartImage from "../../assets/images/chartImage.png";

export const FinancialEfficiency = () => {
  const reportData = [
    {
      links: "2022 Annual Report",
    },

    {
      links: "2022 Financial Statement",
    },
    {
      links: "2022 Tax Information",
    },
    {
      links: "2021 Annual Report",
    },
    {
      links: "2021 Tax information",
    },
    {
      links: "2021 Financial Statement ",
    },
  ];

  return (
    <Container>
      <RightSection>
        <BigTitle>Our Financial Efficiency</BigTitle>

        <Styledtext color="white" textAlign="left" resTextAlign="left">
          Save the Children has a proven track record of using donations
          efficiently and effectively. We work to maximize the value of every
          dollar we receive so that
          <b style={{ padding: "0 0.4rem" }}>
            less than 7% of every dollar spent goes to administrative costs.
          </b>
        </Styledtext>
        <LinksContainer>
          {reportData.map(({ links }) => (
            <StyledLink>{links}</StyledLink>
          ))}
        </LinksContainer>
      </RightSection>
      <ChartWrapper>
        <ChartImage src={chartImage} />
      </ChartWrapper>
    </Container>
  );
};
