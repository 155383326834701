import React from "react";
import {
  Container,
  RightSection,
  BigTitle,
  Styledtext,
  StyledLink,
  ChartWrapper,
  Wrapper,
} from "./aboutUs.styles";
import { Button } from "../button/button.component";
import { VideoPlayer } from "../VideoPlayer/videoPlayer.section";
import { useNavigate } from "react-router-dom";


export const AboutUs = () => {
  const navigation = useNavigate()
  return (
    <Container>
      <Wrapper>
        <ChartWrapper>
          <VideoPlayer />
        </ChartWrapper>
        <RightSection>
          <BigTitle>About Save the Children</BigTitle>

          <Styledtext color="white" textAlign="left" resTextAlign="left">
            Save the Children believes every child deserves a future. Save the
          </Styledtext>

          <Styledtext color="white" textAlign="left" resTextAlign="left">
            Our founder <StyledLink>Eglantyne Jebb</StyledLink> saw children
            dying of starvation and wracked with disease after the end of the
            First World War. So, in 1919, she launched the Save the Children
            Fund to raise much-needed funds to end children’s suffering across
            war-torn Europe.
          </Styledtext>

          <Styledtext color="white" textAlign="left" resTextAlign="left">
            Today, over 100 years later, we work in{" "}
            <StyledLink>the United States and around the world</StyledLink> to
            give children a healthy start in life, the opportunity to learn and
            protection from harm. When <StyledLink>crisis strikes </StyledLink>{" "}
            and children are most vulnerable, we are always among the first to
            respond and the last to leave. We do whatever it takes for children
            - every day and in times of crisis – transforming their lives and
            the future we share.
          </Styledtext>
        </RightSection>
      </Wrapper>
      <Button
        btnText="Donate Now"
        borderRadius="5px"
        // background={"#009ca6"}
        fontSize={"1.4rem"}
        paddingVertical="0.7rem"
        paddingHorizontal={"3rem"} onClick={()=>{navigation("/donate")}}
      />
    </Container>
  );
};
