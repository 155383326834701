import React from "react";
import { Container } from "./ourPartners.styles";
import { PartnerHeroSection } from "../../components/heroSection/heroSection.component";
import { PartnerPrenews } from "../../components/verticalCard/verticalCard.component";
import { BottomFormSection } from "../../components/bottomFormSection/bottomFormSection.component";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import {
  SecondWaysToHelpCardDuplicate,
  SecondWaysToHelpCardWithBackground,
} from "../../components/fulfillingMission/fulfillingMission.component";
import { ToastContainer } from "react-toastify";

const OurPartner = () => {
  return (
    <Container>
      <Header />
      <PartnerHeroSection />
      <SecondWaysToHelpCardDuplicate />
      <PartnerPrenews />
      <SecondWaysToHelpCardWithBackground />
      <BottomFormSection />
      <Footer />
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default OurPartner;
