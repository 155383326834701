import React from "react";
import {
  VerticalCardContainer,
  Title,
  BigTitle,
  ButtonWrapper,
  PreNewsContainer,
  Container,
  Styledtext,
  Wrapper,ComponentWrapper,
  ExternalContainer,
} from "./ourResourcesCard.styles";
import { Button } from "../button/button.component";
import CardImage3 from "../../assets/images/image14.jpg";
import CardImage2 from "../../assets/images/image15.jpg";
import CardImage1 from "../../assets/images/image16.jpg";

import image1 from "../../assets/images/nat1.jpg";
import image2 from "../../assets/images/nat2.jpg";
import image3 from "../../assets/images/nat3.jpg";
import image4 from "../../assets/images/nat4.jpg";
import { useNavigate } from "react-router-dom";

export const OurResourcesCard = ({ image, buttonText, title }) => {
  return (
      <VerticalCardContainer backgroundImage={image}>
        <Wrapper>
          <Title>{title ? title : "Join us."}</Title>
          <ButtonWrapper>
            <Button
              fontSize={"1.2rem"}
              btnText={buttonText ? buttonText : "Join Us"}
              background={"#009ca6"}
              paddingVertical={"0.6rem"}
            />
          </ButtonWrapper>
        </Wrapper>
      </VerticalCardContainer>
  );
};

export const OurSecondResourcesCard = ({ image, buttonText, title, description }) => {

    const navigation = useNavigate();
  return (
    <ExternalContainer description={description}>
      <VerticalCardContainer backgroundImage={image} description={description}>
        <Wrapper>
          <Title>{title ? title : "Join us."}</Title>
          <ButtonWrapper>
            <Button
              fontSize={"1.2rem"}
              btnText={buttonText ? buttonText : "Join Us"}
              background={"#009ca6"}
              paddingVertical={"0.6rem"}
              onClick={() => {
                navigation("/donate");
              }}
            />
          </ButtonWrapper>
        </Wrapper>
      </VerticalCardContainer>
      {description && <Styledtext>{description}</Styledtext>}
    </ExternalContainer>
  );
};

export const OurResources = () => {
  const ourResourcesData = [
    {
      image: CardImage1,
      title: "News Room",
      buttonText: "Read More",
    },
    {
      image: CardImage2,
      title: "Resource Library",
      buttonText: "Browse",
    },
    {
      image: CardImage3,
      title: "Publications",
      buttonText: "Learn More",
    },
  ];

  return (
    <Container>
      <BigTitle>Our Resources</BigTitle>
      <PreNewsContainer>
        {ourResourcesData.map(({ image, title, buttonText }) => (
          <OurResourcesCard
            image={image}
            title={title}
            buttonText={buttonText}
          />
        ))}
      </PreNewsContainer>
    </Container>
  );
};

export const SecondOurResources = () => {
  const ourResourcesData = [
    {
      image: image1,
      title: "Ways to Give",
      buttonText: "Learn More",
      description:
        "We make it easy to help the children, in ways that work for you. Send a gift from our catalog, sponsor a child in need… there are so many ways you can help save children around the world.",
    },
    {
      image: image2,
      title: "Start a Fundraiser",
      buttonText: "Get Started",
      description:
        "Whether it’s an athletic challenge, a special occasion, or your own event, you can put the “fun” in fundraising for children who need it most.",
    },
  ];

  return (
    <Container>
      <ComponentWrapper>
        <BigTitle>Support Our Mission</BigTitle>
        <PreNewsContainer>
          {ourResourcesData.map(({ image, title, buttonText, description }) => (
            <OurSecondResourcesCard
              image={image}
              title={title}
              buttonText={buttonText}
              description={description}
            />
          ))}
        </PreNewsContainer>
      </ComponentWrapper>

      <ComponentWrapper>
        <BigTitle>Get Involved</BigTitle>
        <PreNewsContainer>
          <OurSecondResourcesCard
            image={image3}
            title={"Volunteer"}
            buttonText={"Learn How"}
            description={
              "Save the Children volunteers are an integral part of our organization, and work in a variety of ways to contribute to our cause. They are involved in important work to create positive change for children everywhere."
            }
          />
        </PreNewsContainer>
      </ComponentWrapper>

      <ComponentWrapper>
        <BigTitle>Advocate for Children</BigTitle>
        <PreNewsContainer>
          <OurSecondResourcesCard
            image={image4}
            title={"Advocate for Children"}
            buttonText={"Learn How"}
            description={
              "Use your voice – and your social networks – to take action on critical issues and urge government leaders to make children a top priority. Children are our future."
            }
          />
        </PreNewsContainer>
      </ComponentWrapper>
    </Container>
  );
};
