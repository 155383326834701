import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text, Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";

export const FormSectionContainer = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 2rem 3vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.4rem;
  width: 86%;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 750px) {
    align-items: center;
  }
`;

export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.2rem;
  color: #000;
`;

export const BodySection = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  gap: 1.4rem;
`;

export const BodyText = styled(Text)`
  font-size: 1rem;
  color: ${colors.black.regular};
`;

export const Form = styled.form`
  /* background: green; */
  padding: 0;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: flex-end;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
`;
export const Wrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: 26%;
  padding: 0;
  justify-content: flex-start;
  /* gap: 0.5rem; */
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: max-content;
  padding: 0;
  justify-content: flex-start;
`;
export const BulletPoints = styled(FlexibleDiv)`
  background: #fff;
  padding: 0.3rem;
  width: max-content;
  border-radius: 50%;
`;
export const BulletPointsText = styled(Heading)`
  font-weight: bold;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.2rem;
  word-spacing: 0.2rem;
`;

export const StyledLink = styled(Link)`
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffb300;
  margin: 0 0.3rem;
  text-decoration: underline;
`;
