import React from "react";
import ReactPlayer from "react-player/lazy";

const VideoFallBack = () => {
  return (
    <iframe
      title="video-play-icon"
      style={{ border: "none" }}
      src="https://embed.lottiefiles.com/animation/74065"
    ></iframe>
  );
};
export const VideoPlayer = ({ videoUrl, videoThumbnail }) => {
  return (
    <ReactPlayer
      url={videoUrl ? videoUrl : "https://youtu.be/LoEyd6_jztc"}
      height={"100%"}
      width={"100%"}
      loop={true}
      light={videoThumbnail}
      className="videoPlayer"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
      }}
      controls={true}
      pip={true}
      playing={true}
      fallback={<VideoFallBack />}
    />
  );
};
