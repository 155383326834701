import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text, Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";

export const Container = styled(FlexibleDiv)`
  /* background: green; */
  padding: 2rem 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 86%;
  gap: 1rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 750px) {
    align-items: center;
  }
`;
export const NewsContainer = styled(FlexibleDiv)`
  background: #eeede7;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  padding: 2rem;
  @media (max-width: 750px) {
   /* padding:0; */
  }
`;

export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  color: ${colors.black.regular};
`;

export const HorizontalCardContainer = styled(FlexibleDiv)`
  background: #eeede7;
  height: max-content;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  @media (max-width: 800px) {
    flex-direction: row;
  }
  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const CardImageWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  height: max-content;
  width: 35%;
  @media (max-width: 750px) {
   width:100%;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 30vh;
  @media (max-width: 750px) {
    width: 100%;
   height:50vw;
  }
`;

export const BodyWrapper = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  gap: 0.5rem;
  @media (max-width: 750px) {
    width: 100%;
    align-items:center;
  }
`;

export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: 1.7rem;
  line-height: 1.9rem;
  color: #000;
  @media (max-width: 750px) {
  text-align:center;
  }
`;

export const Description = styled(Text)`
  font-family: "Lato";
  font-size: 1.2rem;
  /* line-height: 1.3rem; */
  color: ${colors.black.regular};
  @media (max-width: 750px) {
   text-align:center;
  }
`;

export const ButtonWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: 40%;
  padding: 0;
  justify-content: flex-start;
  margin-top: 1rem;
  @media (max-width: 750px) {
   margin-top:0.5rem;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.1rem;
  font-weight: 600;
  color: #009ca6;
  margin: 0 0.3rem;
  align-self: flex-end;
  text-decoration: underline;
`;
