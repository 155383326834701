import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";

export const PreNewsContainer = styled(FlexibleDiv)`
  /* background: green; */
  padding: 2rem 0;
  justify-content: space-around;
  align-items: flex-start;
  width: 86%;
  gap: 2rem 1rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 850px) {
    justify-content: space-between;
    gap: 2rem 0.5rem;
  }
  @media (max-width: 800px) {
    justify-content: space-between;
    gap: 3rem;
  }
`;

export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  text-align: center;
  width: 100%;
  color: ${colors.black.strong};
`;

export const VerticalCardContainer = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 31%;
  gap: 0.5rem;
  @media (max-width: 850px) {
    width: 48%;
  }
  @media (max-width: 800px) {
    width: 100%;
    align-items:center;
  }
`;



export const CardImageWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  height: max-content;
  width: 100%;
`;

export const CardImage = styled.img`
  width: 100%;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : "50vh")};
  @media (max-width: 800px) {
    width: 80vw;
    height: 80vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 80vw;
  }
`;
export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.1rem;
  line-height: 2.7rem;
  text-align:left;
  color: ${colors.black.strong};
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const TextWrapper = styled(FlexibleDiv)`
  /* background: red; */
  padding: 0;
  justify-content: flex-start;
  margin-top: 1rem;

`;

export const StyledText = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${colors.black.regular};
  @media (max-width: 800px) {
    text-align:center;
  }
`;


export const StyledLink = styled(Link)`
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #009ca6;
  margin: 0 0.3rem;
  align-self: flex-end;
  text-decoration: underline;
`;

export const StyledTitleLink = styled(Link)`
  font-size: 1.3rem;
  color: #009ca6;
  /* margin: 0 0.3rem; */
  word-wrap: wrap;
  text-decoration: underline;
  /* background:blue; */
`;