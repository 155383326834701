import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Link } from "react-router-dom";
import { Text, Title } from "../typography/text.component";
import { colors } from "../../infrastructure/theme/colors";

export const Container = styled(FlexibleDiv)`
  /* background: green; */
  padding: 2rem 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 86%;
  gap: 1.5rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const BigTitle = styled(Title)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  color: ${colors.black.strong};
`;
export const BodyText = styled.p`
   color: #222221;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align:center;
`;

export const ValuesWrapper = styled(FlexibleDiv)`
flex-direction:column;
gap:0.3rem;
padding:0;

`;
export const StyledText = styled(Text)`
   color: #222221;
  font-size: 1.1rem;
  font-weight: bold;
  /* margin-top: 0.4rem; */
`;

export const StyledLink = styled(Link)`
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #009ca6;
  margin: 0 0.3rem;
  align-self: flex-end;
  text-decoration: underline;
`;

export const ButtonWrapper = styled(FlexibleDiv)`
  
  gap: 1rem;
  padding: 0;
  justify-content: space-between;
`;