import React from "react";
import { Container } from "./waysToHelp.styles";
import { ThirdHeroSection } from "../../components/heroSection/heroSection.component";
import { BottomFormSection } from "../../components/bottomFormSection/bottomFormSection.component";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { SecondOurResources } from "../../components/ourResourcesCard/ourResourcesCard.component";
import { WaysToHelpCard } from "../../components/fulfillingMission/fulfillingMission.component";
import { ToastContainer } from "react-toastify";

const WaysToHelp = () => {
  return (
    <Container>
      <Header />
      <ThirdHeroSection />
      <WaysToHelpCard />
      <SecondOurResources />
      <BottomFormSection />
      <Footer />
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default WaysToHelp;
