import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
// import { fontSizes } from "../../infrastructure/theme/fonts";

export const HeaderContainer = styled.div`
  width: 100%;
  background-color: #eeede7;
  height: max-content;

  /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */

  .innerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0.3rem 3%;
    flex-direction: column;
  }
  @media (max-width: 1100px) {
    .innerWrapper {
      padding: 0 3%;
    }
  }
  @media (max-width: 800px) {
    .innerWrapper {
      position: relative;
    }
  }

  @media (max-width: 970px) {
    .innerWrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const Wrapper = styled(FlexibleDiv)`
  padding:0px;
  justify-content: space-between;
   @media (max-width: 970px) {
width:max-content;
   }
`;
