import React from "react";
import {
  VerticalCardContainer,
  CardImageWrapper,
  CardImage,
  Title,
  Description,
  Container,
} from "./thirdVerticalCard.styles";
import Image from "../../assets/images/thirdCardImage.jpg";

export const ThirdVerticalCard = ({ image, buttonText, title }) => {
  return (
    <Container>
      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={Image} />
        </CardImageWrapper>
        <Description>
          "I have seen firsthand everything Save the Children does to keep
          children healthy, happy and safe. Any amount will help make change for
          children in the U.S. and around the world."
        </Description>
        <Title>Jennifer Garner, Save the Children Ambassador and Trustee</Title>
      </VerticalCardContainer>
    </Container>
  );
};
