import React from "react";
import {
  HerosSectionContainer,
  TitleTextWrapper,
  TitleText,
  StyledButton,
} from "./heroSection.styles";
import { colors } from "../../infrastructure/theme/colors";
import Banner from "../../assets/images/banner.jpg";
import Banner2 from "../../assets/images/banner2.jpg";
import Banner4 from "../../assets/images/banner5.jpg";
import { useNavigate } from "react-router-dom";

export const HeroSection = () => {
  const navigation = useNavigate()
  return (
    <HerosSectionContainer>
      <TitleTextWrapper>
        <TitleText>TODAY YOUR GIFT GETS</TitleText>
        <TitleText top="-10px" color={colors.primary}>
          MATCHED 2X TO HELP 2X
        </TitleText>
        <TitleText top="-10px">MORE CHILDREN AT RISK</TitleText>
      </TitleTextWrapper>
      <StyledButton  onClick={()=>{navigation("/donate")}} btnText="Donate Today" paddingHorizontal={"3rem"} />
    </HerosSectionContainer>
  );
};

export const SecondHeroSection = () => {
  return (
    <HerosSectionContainer bgImage={Banner}>
      <TitleTextWrapper>
        <TitleText>What We Do</TitleText>
      </TitleTextWrapper>
    </HerosSectionContainer>
  );
};

export const ThirdHeroSection = () => {
  return (
    <HerosSectionContainer bgImage={Banner2}>
      <TitleTextWrapper>
        <TitleText>HOW CAN YOU HELP</TitleText>
        <TitleText top="-10px">CHILDREN IN NEED? </TitleText>
      </TitleTextWrapper>
    </HerosSectionContainer>
  );
};

export const FourthHeroSection = () => {
  return (
    <HerosSectionContainer bgImage={Banner}>
      <TitleTextWrapper>
        <TitleText>GIVE WITH IMPACT:</TitleText>

        <TitleText top="-10px">DONATE MONTHLY</TitleText>
      </TitleTextWrapper>
    </HerosSectionContainer>
  );
};


export const PartnerHeroSection = () => {
  return (
    <HerosSectionContainer bgImage={Banner4}>
      <TitleTextWrapper>
        <TitleText>OUR PARTNERS</TitleText>
      </TitleTextWrapper>
    </HerosSectionContainer>
  );
};
