import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";

export const CardContainer = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 2rem 3vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: 86%;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.2rem;
  color: #000;
  text-align: center;
  width:100%;
`;

export const BodySection = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  gap: 1rem;
`;

export const TextWrapper = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  gap: 0.3rem;
  justify-content: flex-start;
`;

export const BodyText = styled.p`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align:center;
   color: #222221;
  word-wrap: wrap;
`;

export const BulletPointsSection = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  padding-left: 2vw;
  flex-direction: column;
  gap: 0.4rem;
`;
export const Wrapper = styled(FlexibleDiv)`
  /* background: red; */
  padding: 0;
  justify-content: flex-start;
  align-items: ${({ textColor }) => (textColor ? "flex-start" : "center")};
  gap: 0.5rem;
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
export const BulletPoints = styled(FlexibleDiv)`
  background: #000;
  padding: 0.2rem;
  width: max-content;
  border-radius: 50%;
  margin-top: ${({ textColor }) => (textColor ? "0.3rem" : "0px")};
  @media (max-width: 800px) {
    width: max-content;
    margin-top: 0.3rem;
  }
`;
export const BulletPointsText = styled.p`
  /* font-weight: bold; */
   color: #222221;
   width:95%;
  font-size: 1.2rem;
  line-height: 1.3rem;
  word-spacing: 0.2rem;
  @media (max-width: 800px) {
    width: 90%;
    font-size: 1.1rem;
    line-height: 1.2rem;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.2rem;
  color: #009ca6;
  margin: 0 0.3rem;
  word-wrap: wrap;
  text-decoration: underline;
  /* background:blue; */
`;
