import styled from "styled-components";
import { colors } from "../../../infrastructure/theme/colors";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Link } from "react-router-dom";

export const BottomSectionWrapper = styled(FlexibleDiv)`
  /* border-top:1px solid ${colors.iconColor}; */
  width: 86%;
  gap: 1.4rem;

  .container a:last-child {
    border-right: none;
    /* padding: 0 1rem; */
  }
`;

export const StyledLink = styled(Link)`
  color: #c9cbcc;
  font-size: 0.93rem;
  font-weight: bold;
  margin-top: 0.4rem;
  text-decoration: none;
  border-right: 1px solid #fff;
  padding: 0 1rem;
  @media (max-width: 800px) {
    border-right:none;
    padding:0;
    gap:1rem;

  }
`;

export const BottomTextSection = styled(FlexibleDiv)`
  justify-content: flex-start;
  gap: 1rem;
  padding: 0;

  @media (max-width: 1250px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 750px) {
  p{
    text-align:center;
  }
  }
`;

