import React from "react";
import {
  VerticalCardContainer,
  CardImageWrapper,
  CardImage,
  Title,
  TextWrapper,
  StyledText,
  StyledTitleLink,
  StyledLink,
  BigTitle,
  PreNewsContainer,
} from "./fourthVerticalCard.styles";
import CardImage1 from "../../assets/images/imagea.webp";
import CardImage2 from "../../assets/images/imageb.webp";
import CardImage3 from "../../assets/images/image3.webp";
import imageA from "../../assets/images/a.webp";
import imageB from "../../assets/images/b.webp";
import imageC from "../../assets/images/c.webp";
import imageD from "../../assets/images/d.webp";
import imageE from "../../assets/images/e.webp";

export const Prenews = () => {
  return (
    <PreNewsContainer>
      <BigTitle>Learn More About Our Work</BigTitle>
      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={imageA} />
        </CardImageWrapper>
        <Title>Health Programs</Title>
        <TextWrapper>
          <StyledText>
            As a global leader in <StyledLink>child health</StyledLink>, Save
            the Children keeps children healthy and thriving. Today, as children
            around the world are growing up in a deadly pandemic, we are
            supporting frontline health workers and providing education and
            hygiene supplies to help families and communities protect themselves
            against COVID-19 and stay healthy.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={imageB} />
        </CardImageWrapper>
        <Title>Education Programs</Title>
        <TextWrapper>
          <StyledText>
            From rural America’s poorest communities to conflict-affected
            countries, Save the Children ensures{" "}
            <StyledLink>children are educated</StyledLink>. But for the first
            time in history, an entire generation of children globally have had
            their education disrupted. That's why we're supporting distance
            learning, ensuring that children who rely on school meal programs
            don’t go hungry and advocating to ensure children’s right to an
            education.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={imageC} />
        </CardImageWrapper>
        <Title>Protection Programs</Title>
        <TextWrapper>
          <StyledText>
            Save the Children was the first global organization devoted solely
            to <StyledLink>protecting children from harm</StyledLink>. Today, as
            COVID-19 threatens to exacerbate the risk of violence against
            children, we're doing whatever it takes to keep the most vulnerable
            children safe from risks now heightened during the pandemic.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={imageD} />
        </CardImageWrapper>
        <Title>Emergency Response</Title>
        <TextWrapper>
          <StyledText>
            Save the Children is the global leader in child-focused{" "}
            <StyledLink>humanitarian response</StyledLink>. We’ve been a
            lifeline for families fleeing violence in Syria, Venezuela, Myanmar
            and dozens of other crises around the world. Right now, we are
            engaged in the most sweeping humanitarian response in our history to
            help protect children from the unique risks because of COVID-19.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={imageE} />
        </CardImageWrapper>
        <Title>Policy and Advocacy</Title>
        <TextWrapper>
          <StyledText>
            Save the Children works to ensure{" "}
            <StyledLink>children’s voices are heard</StyledLink>, their rights
            are realized and their issues are given top priority. That's why
            U.S. policymakers look to us as the leading expert on childhood,
            both here at home and abroad.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <Title>Localization</Title>
        <TextWrapper>
          <StyledText>
            Save the Children believes that{" "}
            <StyledLink>localized approaches</StyledLink> deliver the best
            results for children, and we aim to act as an ally to local
            communities and actors – including children – by elevating their
            voice and leadership in driving sustainable development and
            humanitarian action.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>
    </PreNewsContainer>
  );
};

export const SecondPrenews = () => {
  return (
    <PreNewsContainer>
      <BigTitle>Learn More About Save the Children</BigTitle>
      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage imgHeight={"30vh"} src={CardImage1} />
        </CardImageWrapper>
        <StyledTitleLink>Give with Confidence</StyledTitleLink>
        <TextWrapper>
          <StyledText>
            For over 100 years, Save the Children has been one of the charities
            you can trust, with a track record of using donations efficiently
            and effectively.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage imgHeight={"30vh"} src={CardImage2} />
        </CardImageWrapper>
        <StyledTitleLink>Where We Work</StyledTitleLink>
        <TextWrapper>
          <StyledText>
            We work in over 200 of the poorest communities in rural America –
            where we’re often the only child-focused nonprofit. Globally, we're
            working in more than 100 countries.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage imgHeight={"30vh"} src={CardImage3} />
        </CardImageWrapper>
        <StyledTitleLink>Charity Ratings</StyledTitleLink>
        <TextWrapper>
          <StyledText>
            View our ratings and learn why we rank high among other nonprofit
            organizations.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>
    </PreNewsContainer>
  );
};
