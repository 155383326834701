import React from "react";
import { Link } from "react-router-dom";

import { Wrapper } from "./pageLinks.styles";

export const PageLinkSection = ({ display }) => {
  return (
    <Wrapper display={display}>
      <Link to="/whoWeAre">Who We Are</Link>
      <Link to="/whatWeDo">What We Do</Link>
      <Link to="/waysToHelp">Ways To Help</Link>
      <Link to="/monthlySupport">Monthly Support</Link>
      <Link to="/ourPartners">Our Partners</Link>
    </Wrapper>
  );
};
