import React, { useState } from "react";

/*Importing logo image and styles*/
import { HeaderContainer, Wrapper } from "./header.styles";
import { LogoSection } from "./Sections/LogoSection/logo.section";
import { NavIconSection } from "./Sections/NavIcon/navIcon.section";
// import { ActionSection } from "./Sections/Actions/action.section";
// import { PageLinkSection } from "./Sections/PageLinks/pageLinks.section";
import { LinkSection } from "./Sections/LinkSection/linkSection.section";

const Header = () => {
  const [displayNav, setDisplayNav] = useState(false);
  const toggleNav = () => {
    setDisplayNav(!displayNav);
    console.log(displayNav);
  };

  return (
    <HeaderContainer >
      <div className="innerWrapper">
        <LogoSection />
        <Wrapper>
          <LinkSection displayNav={displayNav} />
          <NavIconSection toggleNav={toggleNav} displayNav={displayNav} />
        </Wrapper>
      </div>
    </HeaderContainer>
  );
};

export default Header;
