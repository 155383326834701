import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";

export const Container = styled(FlexibleDiv)`
  background: #eeede7;
  padding: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  width: 86%;
  border-radus: 5px;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 800px) {
    flex-direction:column-reverse;
    gap:2rem;
  }
`;

export const RightSection = styled(FlexibleDiv)`
  /* background: blue; */
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 50%;
  padding: 0;
  @media (max-width: 800px) {
    width:100%;
  }
`;


export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  text-align:center;
  width:100%;
  color: ${colors.black.strong};
`;
export const Styledtext = styled.p`
  color: #222221;
  font-size: 1.2rem;
  margin-top: 0.4rem;
`;

export const LinksContainer = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  width:max-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
  margin-top:1rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
 
`;



export const StyledLink = styled(Link)`
  font-size: 1.2rem;
  font-weight:bold;
  line-height: 1.5rem;
  color: #009ca6;
  text-decoration: underline;
`;

export const ChartWrapper = styled(FlexibleDiv)`
  width: 50%;
  justify-content: center;
  align-items: flex-start;
  /* background: yellow; */
  padding: 0;
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
  }
`;
export const ChartImage = styled.img`
  width: 80%;
  height: auto;
  @media (max-width: 800px) {
    width: auto;
    height: 30vh;
  }
`;
