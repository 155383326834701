import styled from "styled-components";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";

export const Container = styled(FlexibleDiv)`
  /* background: red; */
  padding:0;
  flex-direction: column;
  justify-content: center;
`;


