import React from "react";
import { Container } from "./whoWeAre.styles";
import { Prenews } from "../../components/SecondVerticalCard/SecondVerticalCard.component";
import { BottomFormSection } from "../../components/bottomFormSection/bottomFormSection.component";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { OurResources } from "../../components/ourResourcesCard/ourResourcesCard.component";
import { FinancialEfficiency } from "../../components/financialEfficiancy/financialEfficiancy.component";
import { WhyTheBest } from "../../components/whyTheBest/whyTheBest.component";
import { ThirdVerticalCard } from "../../components/thirdVerticalCard/thirdVerticalCard.component";
import { OurValues } from "../../components/ourValues/ourValues.component";
import { AboutUs } from "../../components/aboutUs/aboutUs.component";
import { ToastContainer } from "react-toastify";

const WhoWeAre = () => {
  return (
    <Container>
      <Header />
      <AboutUs />
      <OurValues />
      <ThirdVerticalCard />
      <WhyTheBest />
      <FinancialEfficiency />
      <Prenews />
      <OurResources />
      <BottomFormSection />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default WhoWeAre;
