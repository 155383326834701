import styled from "styled-components";
// import { colors } from "../../../infrastructure/theme/colors";
// import { Button } from "../../button/button.component";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Text, Title } from "../../typography/text.component";
import { Link } from "react-router-dom";
// import { Input } from "../../input/input.component";

export const MiddleSectionWrapper = styled(FlexibleDiv)`
  width: 25%;
  min-width: 25rem;
  align-items: flex-start;
  gap:1rem;
  /* background-color: red; */

  @media (max-width: 1280px) {
    width: 50%;
    min-width: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    p {
      text-align: center;
    }
    input {
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
    }
  }
`;

export const Heading = styled(Title)`
  color: #c9cbcc;
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin-bottom: 1rem;
`;
export const Styledtext = styled(Text)`
  color: #c9cbcc;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.4rem;
`;

export const Image = styled.img`
  width: auto;
  height: ${({ height }) => (height ? height : "3rem")};
`;
export const Image1 = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 750px) {
    width:40%;
  }
`;

export const StyledLink = styled(Link)`
  color: #c9cbcc;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.4rem;
  text-decoration: underline;
`;