import React from "react";
import {
  CardContainer,
  Title,
  BodySection,
  BodyText,
  BulletPointsSection,
  Wrapper,
  BulletPoints,
  BulletPointsText,
  StyledLink,
  TextWrapper,
} from "./whyTheBest.styles";

export const WhyTheBest = () => {
 
  
  return (
    <CardContainer>
      <Title>
        Why Is Save the Children the Best Charity to Donate To in 2024?
      </Title>
      <BodySection>
        <TextWrapper>
          <BodyText>
            There's never been a better time
            <StyledLink> to give back to children in need</StyledLink>
            and support charities in 2024 that invest in the future we all
            share.
          </BodyText>
        </TextWrapper>
        <BulletPointsSection>
          <Wrapper>
            <BulletPoints />
            <BulletPointsText>
              Save the Children is recognized by
              <StyledLink>CharityWatch</StyledLink>
              <b>as a Top Charity for Child Protection and Sponsorship </b>
            </BulletPointsText>
          </Wrapper>
          <Wrapper>
            <BulletPoints />
            <BulletPointsText>
              Save the Children is on
              <StyledLink>Charity Navigator</StyledLink> 's list of
              <b> Best Charities for Refugee and Immigration </b>
            </BulletPointsText>
          </Wrapper>
          <Wrapper>
            <BulletPoints />
            <BulletPointsText>
              <StyledLink>Charity Navigator</StyledLink> counts us among the
              <b>Most Followed Charities</b>
            </BulletPointsText>
          </Wrapper>
          <Wrapper>
            <BulletPoints />
            <BulletPointsText>
              Save the Children has garnered
              <StyledLink>top ratings</StyledLink>
              <b>among other nonprofit organizations</b>
            </BulletPointsText>
          </Wrapper>
        </BulletPointsSection>
      </BodySection>
    </CardContainer>
  );
};



export const SecondWhyTheBest = () => {
  return (
    <CardContainer>
      <Title>
        Thanks to your recurring donations, we've achieved tremendous results
      </Title>
      <BodySection>
        <TextWrapper>
          <BodyText>
            Through Save the Children’s work
            <b> – every second of every day -</b>a hungry child is fed, a sick
            child gets care, a young mind is learning, a vulnerable child is
            protected, or a family gains tools to overcome poverty.
          </BodyText>
        </TextWrapper>
        <BulletPointsSection>
          <Wrapper textColor>
            <BulletPoints textColor />
            <BulletPointsText textColor>
              Since our founding over 100 years ago,
              <b>we’ve changed the lives of over 1 billion children.</b>
            </BulletPointsText>
          </Wrapper>
          <Wrapper textColor>
            <BulletPoints textColor />
            <BulletPointsText textColor>
              Since the start of the COVID-19 pandemic, globally,
              <b>
                we’ve trained or supported 95,000 health workers and provided
                762,000 families with access to safe water
              </b>
            </BulletPointsText>
          </Wrapper>
          <Wrapper textColor>
            <BulletPoints textColor />
            <BulletPointsText textColor>
              We’ve helped <b>9 million children access quality education</b>
              across the world, including more than <b>4.5 million girls.</b>
            </BulletPointsText>
          </Wrapper>
          <Wrapper textColor>
            <BulletPoints textColor />
            <BulletPointsText textColor>
              Across America, we’ve delivered nearly
              <b>5 million nourishing meals to children</b> in poor, rural
              communities.
            </BulletPointsText>
          </Wrapper>
        </BulletPointsSection>
      </BodySection>
    </CardContainer>
  );
};