import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title as Heading } from "../typography/text.component";
import { colors } from "../../infrastructure/theme/colors";
import bgImage from "../../assets/images/CardImage1.jpg";

export const ExternalContainer = styled(FlexibleDiv)`
  /* background: red; */
  /* margin-bottom: 2rem; */
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
  gap: 0.5rem;
  border-radius: 5px;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const Container = styled(FlexibleDiv)`
  background: #eeede7;
  padding: 1rem 0;
  margin-bottom: 2rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 86%;
  gap: 0.5rem;
  border-radus: 5px;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const ComponentWrapper = styled(FlexibleDiv)`
  padding: 1rem 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* width: 86%; */
  gap: 0.5rem;
  border-radus: 5px;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  color: ${colors.black.strong};
`;

export const PreNewsContainer = styled(FlexibleDiv)`
  /* background: green; */
  padding: 1rem;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2rem 1rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 930px) {
    justify-content: space-between;
    padding: 0.3rem;
  }
  @media (max-width: 850px) {
    justify-content: space-between;
    gap: 2rem 0.5rem;
  }
  @media (max-width: 800px) {
    justify-content: space-between;
    gap: 3rem;
  }
`;

export const VerticalCardContainer = styled(FlexibleDiv)`
  background: url(${({ backgroundImage }) =>
    backgroundImage ? backgroundImage : bgImage});
  background-repeat: round;
  background-size: cover;
  height: ${({ description }) => (description ? "40vh" : "30vh")};
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: ${({ description }) => (description ? "100%" : "32%")};
  gap: 0.5rem;
  border-radius: 7px;
  overflow: hidden;

  @media (max-width: 1000px) {
    height: ${({ description }) => (description ? "70vh" : "30vh")};
  }
  @media (max-width: 930px) {
    width: ${({ description }) => (description ? "100%" : "48%")};
  }
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    height: ${({ description }) => (description ? "50vh" : "30vh")};
  }

  @media (max-width: 600px) {
 
    height: ${({ description }) => (description ? "40vh" : "30vh")};
  }
`;
export const Wrapper = styled(FlexibleDiv)`
  height: max-content;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  padding: 1rem;
  gap: 1rem;
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: #fff;
  /* @media (max-width: 1150px) {
    font-size: 1.2rem;
    line-height: 1.3rem;
  } */
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const Styledtext = styled.p`
  color: #222221;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 0.4rem;
  text-align: center;
`;

export const ButtonWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: max-content;
  padding: 0;
  justify-content: flex-start;
  /* margin-top:1rem; */
`;
