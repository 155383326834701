import styled from "styled-components";
// import { colors } from "../../../../infrastructure/theme/colors";
import { fontSizes } from "../../../../infrastructure/theme/fonts";

export const Wrapper = styled.div`
  width: 80%;

  min-width: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    font-size: ${fontSizes.body};
    text-decoration: none;
    color: #000;
    text-transform: capitalize;
    font-weight: bold;
  }
  a:hover {
    color: #009ca6;
  }

  @media (max-width: 960px) {
    width: 100%;
    min-width: auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    a {
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;
      text-align: left;
      padding: 1rem;
    }
  }
`;
