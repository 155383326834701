import React from "react";
import { Container } from "./monthlySupport.styles";
import { FourthHeroSection } from "../../components/heroSection/heroSection.component";
import { SecondPrenews } from "../../components/SecondVerticalCard/SecondVerticalCard.component";
import { BottomFormSection } from "../../components/bottomFormSection/bottomFormSection.component";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { SecondWhyTheBest } from "../../components/whyTheBest/whyTheBest.component";
import { AboutUs } from "../../components/aboutUs/aboutUs.component";
import { SecondWaysToHelpCard } from "../../components/fulfillingMission/fulfillingMission.component";
import { ThirdPrenews } from "../../components/verticalCard/verticalCard.component";
import { ToastContainer } from "react-toastify";

const MonthlySupport = () => {
  return (
    <Container>
      <Header />
      <FourthHeroSection />
      <SecondWaysToHelpCard />
      <SecondPrenews />
      <SecondWhyTheBest />
      <ThirdPrenews />
      <AboutUs />
      <BottomFormSection />
      <Footer />
 <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default MonthlySupport;
