import React from "react";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Spacer } from "../../spacer/spacer.component";
import { colors } from "../../../infrastructure/theme/colors";
import { LeftSectionWrapper, Heading, StyledLink } from "./leftSection.styles";
import { Button } from "../../button/button.component";
import { useNavigate } from "react-router-dom";


export const LeftSection = () => {
  const navigation = useNavigate()
  const linkData = [
    {
      title: "Learn More",
      links: [
        { linkLabel: "Why Save the Children?", linkUrl: "#" },
        { linkLabel: "About Us", linkUrl: "#" },
        { linkLabel: "What We Do", linkUrl: "#" },
        { linkLabel: "Where We Work", linkUrl: "#" },
        { linkLabel: "Frequently Asked Questions", linkUrl: "#" },
        { linkLabel: "Charity Ratings", linkUrl: "#" },
        // { linkLabel: "Hire Us", linkUrl: "#" },
      ],
    },
  ];

  return (
    <LeftSectionWrapper paddingVertical="0" alignItems="flex-start">
      {linkData.map(({ title, links }) => (
        <FlexibleDiv
          paddingVertical="0"
          alignItems="flex-start"
          flexDirection="column"
          width="100%"
          // background="pink"
        >
          <Heading>{title}</Heading>
          <Spacer />
          {links.map(({ linkLabel, linkUrl }) => (
            <StyledLink>{linkLabel}</StyledLink>
          ))}

          <Spacer size="small" />
          <Button
            btnText="Donate Now"
            borderRadius="5px"
            background={colors.primary}
            width={"100%"}
            fontSize={"1.2rem"}
            paddingVertical="0.7rem"
             onClick={()=>{navigation("/donate")}}
          />
        </FlexibleDiv>
      ))}
    </LeftSectionWrapper>
  );
};
