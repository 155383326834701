import React from "react";
import { Spacer } from "../spacer/spacer.component";
import {
  Container,
  StyledText,
  StyledLink,
  BigTitle,
  ValuesWrapper,
  BodyText,
  ButtonWrapper,
} from "./ourValues.styles";
import { Button } from "../button/button.component";

export const OurValues = () => {
  const valueData = [
    { values: "Accountability " },
    { values: "Integrity" },
    { values: "Ambition" },
    { values: "Collaboration" },
    { values: "Creativity" },
  ];

  return (
    <Container>
      <BigTitle>Our Values</BigTitle>
      <BodyText>At Save the Children, we live by five Core Values:</BodyText>
      {/* <Spacer /> */}
      <ValuesWrapper>
        {valueData.map(({ values }) => (
          <StyledText>{values}</StyledText>
        ))}
      </ValuesWrapper>

      <BodyText>
        These Core Values drive our long-term strategies and short-term goals,
        and they underpin our mission to inspire breakthroughs in the way the
        world treats children and achieve immediate and lasting impact in their
        lives.
      </BodyText>
      <BodyText>
        Save the Children is committed to advancing diversity, equity and
        inclusion in our workplace as well as in the communities where we work.
        <StyledLink>Learn more about DEI at Save the Children.</StyledLink>
      </BodyText>

      <Spacer size="small" />
      <ButtonWrapper>
        <Button
          btnText="VIEW OUR 
          CODE OF ETHICS"
          borderRadius="5px"
          background={"#009ca6"}
          fontSize={"1.2rem"}
          paddingVertical="0.7rem"
        />
        <Button
          btnText="DOWNLOAD OUR 22-24 STRATEGIC PLAN"
          borderRadius="5px"
          background={"#009ca6"}
          fontSize={"1.2rem"}
          paddingVertical="0.7rem"
        />
      </ButtonWrapper>
    </Container>
  );
};
