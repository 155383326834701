import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title } from "../typography/text.component";
import { Button } from "../button/button.component";
import HeroImage from "../../assets/images/heroImage.jpg";

export const HerosSectionContainer = styled(FlexibleDiv)`
  background: url(${({ bgImage }) => (bgImage ? bgImage : HeroImage)});
  background-size: cover;
  background-repeat: round;
  height: 70vh;
  padding: 0 10vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 1000px) {
    padding: 0 3vw;
  }
  @media (max-width: 750px) {
    height: 40vh;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: stretch;
  }
`;
export const TitleTextWrapper = styled(FlexibleDiv)`
  // background: red;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding:0;
`;
export const TitleText = styled(Title)`
  background: #fff;
  padding: 0.7rem 0.5rem;
  font-family: "Oswald";
  font-size: 3rem;
  margin-top: ${({ top }) => (top ? top : "0px")};
  border-radius: 5px;
  @media (max-width: 750px) {
    font-size: 1.7rem;
    padding: 0.2rem 0.2rem;
  }
`;
export const StyledButton = styled(Button)``;
