export const colors = {
  //...DefaultTheme.colors,

  primary: "#DA291c",
  secondary: "#023828",

  bg: {
    white: "#FFFFFF",
    lightBg: "#c7ddf6",
    darkBg: "#F4F3F4",
  },

  white: "#fff",
  black: {
    strong: "#000",
    regular: "#4d4d4d",
    soft: "#808080",
    light: "##BEC5D1",
  },

  iconColor: "#C3C3C3",

  text: {
    strong: "#060911",
    regular: "#878896",
    // soft: "#7F7F7F",
    // light: "#fff",
  },
  headerShade: {
    strong: "#707070",
    regular: "#8d8d8d",
    soft: "#b8b8b8",
    light: "#e2e2e2",
  },
  subHeaderShade: {
    strong: "#252525",
    regular: "#666666",
    soft: "#929292",
    light: "#bebebe",
  },

  error: "#fb6b6c",
  red: "#fb6b6c",
  green: "#55c595",
  purple: "#85008a",
  teal: "#15a7bf",

  // yellow: {
  //   strong: "#E4EC00",
  // },
  // ui: {
  //   primary: "#262626",
  //   secondary: "#757575",
  //   tertiary: "#F1F1F1",
  //   quaternary: "#FFFFFF",
  //   disabled: "#DEDEDE",
  //   error: "#D0421B",
  //   success: "#138000",
  // },

  // red: {
  //   strong: "#FA1E0E",
  //   regular: "#fb4b3e",
  //   soft: "#fd8f87",
  //   light: "#fd8f87",
  // },
  // green: {
  //   strong: "#00EC18",
  //   regular: "#87eb7e",
  //   soft: "#aaf1a3",
  //   light: "#ccf7c8",
  // },
  // link: {
  //   strong: "#0E49B5",
  //   regular: "#3e6dc4",
  //   soft: "#6e92d3",
  //   light: "#9fb6e1",
  // },
  // blue: {
  //   strong: "#0018EC",
  // },
};
