import React from "react";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Spacer } from "../../spacer/spacer.component";
import { SocialIcon } from "../../socialIcons/socialIcon.component";
import {
  RightSectionWrapper,
  Heading,
  StyledLink,
} from "./rightSection.styles";

export const RightSection = () => {
  const facebook = "#",
    twitter = "#",
    linkedin = "#",
    instagram = "#",
    youtube = "#",
    pinterest = "#";

  const linkData = [
    {
      title: "Contact Us",
      links: [
        {
          text: "501 Kings Highway East, Suite 400, Fairfield, CT 06825 ",
        },
        { text: "Main Office # 1.659.213.5562 | Mon-Fri 9:00am-5:00pm EST" },
        {
          text: "Email: info@joinhelpthechildren.org | 247 ",
        },
        { smallText: "Report fraud, abuse, wrongdoing" },
      ],
    },
  ];

  return (
    <RightSectionWrapper
      paddingVertical="0"
      alignItems="flex-start"
      flexDirection="column"
    >
      {linkData.map(({ title, links }) => (
        <FlexibleDiv
          paddingVertical="0"
          alignItems="flex-start"
          flexDirection="column"
          width="max-content"
          // background="pink"
        >
          <Heading>{title}</Heading>
          <Spacer />
          {links.map(({ text, smallText }) => (
            <StyledLink smallText={smallText}>
              {text ? text : smallText}
            </StyledLink>
          ))}
        </FlexibleDiv>
      ))}

      <FlexibleDiv
        width="max-content"
        paddingVertical="0"
        alignItems="flex-start"
        gap={"1rem"}
      >
        <SocialIcon
          facebook={facebook}
          twitter={twitter}
          linkedin={linkedin}
          instagram={instagram}
          youtube={youtube}
          pinterest={pinterest}
          fontSize={"1.7rem"}
        />
      </FlexibleDiv>
    </RightSectionWrapper>
  );
};
