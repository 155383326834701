import React from "react";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Spacer } from "../../spacer/spacer.component";
import {
  MiddleSectionWrapper,
  Heading,
  Styledtext,
  StyledLink,
  Image,
  Image1,
} from "./middleSection.styles";
import Partner1 from "../../../assets/images/Partner1.webp";
import Partner2 from "../../../assets/images/Partner2.webp";
import Partner3 from "../../../assets/images/Partner3.svg";
import currency from "../../../assets/images/currency.svg";
import logo2 from "../../../assets/images/logo2.png";

export const MiddleSection = () => {
  const partnerImages = [
    { image: Partner1 },
    { image: Partner2 },
    { image: Partner3 },
  ];
  return (
    <MiddleSectionWrapper paddingVertical="0">
      <FlexibleDiv paddingVertical="0" alignItems="flex-start">
        <FlexibleDiv paddingVertical="0" alignItems="flex-start" width={"16%"}>
          <Image1 src={logo2} />
        </FlexibleDiv>
        <FlexibleDiv
          paddingVertical="0"
          alignItems="flex-start"
          flexDirection="column"
          width={"80%"}
        >
          <Heading>An organization you can trust</Heading>
          <Styledtext color="white" textAlign="left" resTextAlign="left">
            When you give, 86% of every dollar goes straight to our mission.
            Find out more.
          </Styledtext>
          <Spacer size="small" />
          <FlexibleDiv
            paddingVertical="0"
            gap={"1rem"}
            justifyContent={"flex-start"}
            resFlexDirection={"row"}
          >
            {partnerImages.map(({ image }) => (
              <Image src={image} />
            ))}
          </FlexibleDiv>
          <Spacer size="small" />
        </FlexibleDiv>
      </FlexibleDiv>
      <FlexibleDiv
        paddingVertical="0"
        gap={"1rem"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        resFlexDirection={"row"}
        resjustifyContent={"center"}
      >
        <Image src={currency} height={"2.4rem"} />
        <StyledLink color="white" textAlign="left" resTextAlign="left">
          We accept cryptocurrency
        </StyledLink>
      </FlexibleDiv>
    </MiddleSectionWrapper>
  );
};
