import React from "react";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { FooterContainer, InnerContainer } from "./footer.styles";
// import Blurb from "../blurb/blurb.component";
// import { Spacer, HorizontalSpacer } from "../spacer/spacer.component";


import { LeftSection } from "./leftSection/leftSection.component";
import { RightSection } from "./rightSection/rightSection.component";
import { MiddleSection } from "./middleSection/middleSection.component";
import { BottomSection } from "./bottomSection/bottomSection.component";

 const Footer = () => {
  return (
    <FooterContainer >
      <InnerContainer  >
        <LeftSection />
        <MiddleSection />
        <RightSection />
      </InnerContainer>
      <FlexibleDiv justifyContent="center" paddingVertical="0" >
        <BottomSection />
      </FlexibleDiv>
    </FooterContainer>
  );
};

export default Footer;
