import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text, Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";

export const Container = styled(FlexibleDiv)`
  /* background: green; */
  padding: 2rem 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 86%;
  gap: 1rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 750px) {
   align-items: center;
  }
`;


export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  color: ${colors.black.strong};
`;

export const HorizontalCardContainer = styled(FlexibleDiv)`
  /* background: #eeede7; */
  height: max-content;
  padding: 1.5rem 0;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`;
export const CardImageWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  /* height: 100%; */
  width: 35%;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 750px) {
    width: 50%;
  }
`;

export const CardImage = styled.img`
  width: max-content;
  height: 10vh;
`;

export const BodyWrapper = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 0;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  gap: 0.5rem;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Description = styled(Text)`
  font-family: "Lato";
  font-size: 1.1rem;
  /* line-height: 1.3rem; */
  color: ${colors.black.regular};
  display: inline;
`;

export const StyledLink = styled(Link)`
  font-size: 1.1rem;
  font-weight: 600;
  color: #009ca6;
  /* margin: 0 0.3rem; */
  align-self: flex-end;
  text-decoration: underline;
`;
