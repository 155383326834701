import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title as Heading, Text } from "../typography/text.component";
import { colors } from "../../infrastructure/theme/colors";
export const Container = styled(FlexibleDiv)`
  /* background: green; */
  padding: 2rem 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ second }) => (second ? "center" : "flex-start")};
  width: 86%;
  gap: 1rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;
export const SecondPreNewsContainer = styled(FlexibleDiv)`
  /* background: green; */
  justify-content: space-around;
  width: 100%;
  align-items: flex-start;
  gap: 2rem 1rem;
  padding: 0;
`;
export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.3rem;
  line-height: 2.4rem;
  color: ${colors.black.regular};
`;

export const PreNewsContainer = styled(FlexibleDiv)`
  /* background: green; */
  padding: 2rem 0;
  justify-content: space-around;
  align-items: flex-start;
  width: 86%;
  gap: 2rem 1rem;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 850px) {
    justify-content: space-between;
    gap: 2rem 0.5rem;
  }
  @media (max-width: 800px) {
    justify-content: space-between;
    gap: 3rem;
  }
`;

export const VerticalCardContainer = styled(FlexibleDiv)`
  /* background: #4a4f53; */
  height: max-content;
  padding: 0;
  flex-direction: column;
  align-items: ${({ second }) => (second ? "center" : "flex-start")};
  justify-content: flex-start;
  width: ${({ second }) => (second ? "23%" : "32%")};
  gap: 0.5rem;
  @media (max-width: 850px) {
    width: 48%;
  }
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
  }
`;

export const Title = styled(Heading)`
  font-family: ${({ second }) => (second ? "Lato" : "Oswald")};
  font-size: ${({ second }) => (second ? "1.2rem" : "1.7rem")};
  line-height: ${({ second }) => (second ? "1.4rem" : "1.9rem")};
  color: ${({ second }) => (second ? "#4a4f53" : "#000")};
  text-transform: ${({ second }) => (second ? "capitalize" : "uppercase")};
  height: ${({ second }) => (second ? "10vh" : "max-content")};
  text-align: ${({ second }) => (second ? "center" : "left")};
  font-weight: ${({ second }) => (second ? "500" : "auto")};
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const StyledText = styled(Text)`
  font-family: "Lato";
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #4a4f53;
  text-align: center;
  font-weight: 500;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const CardImageWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  height: max-content;
  width: 100%;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 55vh;
  object-fit: cover;
  @media (max-width: 800px) {
    width: 80vw;
    height: 80vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 80vw;
  }
`;

export const ButtonWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: ${({ second }) => (second ? "100%" : "70%")};
  padding: 0;
  justify-content: flex-start;
  margin-top: 1rem;
`;
