import React from "react";
// import { Link } from "react-router-dom";
import { colors } from "../../../../infrastructure/theme/colors";
import { Button } from "../../../button/button.component";
import { Wrapper } from "./action.styles";
import { useNavigate } from "react-router-dom";

export const ActionSection = ({display}) => {
  const navigation = useNavigate()
  return (
    <Wrapper display={display}>
      <Button
        btnText="Donate"
        background={colors.primary}
        border={`1px solid ${colors.primary}`}
        fontSize={"1.2rem"}
        fontFamily={"Lato"}
        paddingVertical={"0.5rem"}
        paddingHorizontal={"0.5rem"}
        textTransform={"capitalize"}
        onClick={()=>{navigation("/donate")}}
      />
    </Wrapper>
  );
};
