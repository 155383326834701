import React from "react";
import {
  VerticalCardContainer,
  CardImageWrapper,
  CardImage,
  Title,TextWrapper,
  StyledText,StyledLink,
  PreNewsContainer,
} from "./SecondVerticalCard.styles";
import CardImage1 from "../../assets/images/image11.jpg";
import CardImage2 from "../../assets/images/image12.jpg";
import CardImage3 from "../../assets/images/image13.jpg";

import CardImage4 from "../../assets/images/card1.webp";
import CardImage5 from "../../assets/images/card2.webp";
import CardImage6 from "../../assets/images/card3.webp";


export const Prenews = () => {
 
  return (
    <PreNewsContainer>
      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={CardImage1} />
        </CardImageWrapper>
        <Title>Our Leadership</Title>
        <TextWrapper>
          <StyledText>
            Save the Children’s
            <StyledLink>Senior Leadership Team, Board of Trustees</StyledLink>
            and each and every staff member are dedicated to one mission –
            ensuring children everywhere survive, learn and are protected.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={CardImage2} />
        </CardImageWrapper>
        <Title>Our Strategic Partners</Title>
        <TextWrapper>
          <StyledText>
            From our diverse portfolio of corporations to our philanthropic
            partners, <StyledLink>Save the Children's Partners</StyledLink> help
            us transform children’s lives and the future we share.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage src={CardImage3} />
        </CardImageWrapper>
        <Title>Our Careers</Title>
        <TextWrapper>
          <StyledText>
            When you <StyledLink>work at Save the Children</StyledLink>, you
            join a group of changemakers who are passionate about igniting
            innovations and realizing our vision.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>
    </PreNewsContainer>
  );
};



export const SecondPrenews = () => {
  return (
    <PreNewsContainer>
      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage second src={CardImage4} />
        </CardImageWrapper>
        <Title second>
          Your monthly donation is the most effective way to make a difference.
        </Title>
        <TextWrapper>
          <StyledText>
            The ongoing support of monthly donors helps ensure that Save the
            Children can act quickly when an emergency hits to provide immediate
            disaster relief.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage second src={CardImage5} />
        </CardImageWrapper>
        <Title second>
          Giving monthly is the easiest and most convenient way to give.
        </Title>
        <TextWrapper>
          <StyledText>
            As a monthly donor, you'll know your charitable donation is in place
            for the future - no further action required.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>

      <VerticalCardContainer>
        <CardImageWrapper>
          <CardImage second src={CardImage6} />
        </CardImageWrapper>
        <Title second>
          As a monthly donor, your tax-deductible giving is stress-free.
        </Title>
        <TextWrapper>
          <StyledText>
            By becoming a monthly donor this year, you can rest easy knowing
            your tax-deductible giving is already in place.
          </StyledText>
        </TextWrapper>
      </VerticalCardContainer>
    </PreNewsContainer>
  );
};
