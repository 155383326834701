import React from "react";
import {
  CardContainer,
  Title,
  BodySection,
  BodyText,
  BulletPointsSection,
  Wrapper,
  BulletPoints,
  BulletPointsText,
  StyledLink,
  TextWrapper,
} from "./homeSecondSectionCard.styles";

export const HomeSecondSectionCard = () => {
  const bulletePointsData = [
    {
      text: "$50 can provide enough food to keep 3 children from going hungry for a month",
    },
    {
      text: "$150 can wrap warm, cozy blankets around 30 children affected by conflict",
    },
    {
      text: "$300 can provide 150 face masks to refugee health workers on the front lines",
    },
  ];
  return (
    <CardContainer>
      <Title>
        We work in the hardest-to-reach places, where it’s toughest to be a
        child.
      </Title>
      <BodySection>
        <TextWrapper>
          <BodyText>
            When you give to Save the Children,
            <StyledLink>86% of every dollar</StyledLink>
            goes straight to our mission to provide lifesaving relief to
            children.
          </BodyText>
        </TextWrapper>
        <BulletPointsSection>
          {bulletePointsData.map(({ text }) => (
            <Wrapper>
              <BulletPoints />
              <BulletPointsText>{text}</BulletPointsText>
            </Wrapper>
          ))}
        </BulletPointsSection>
        <BodyText>
          With your support, we can continue our work to keep children in the
          <StyledLink>U.S.</StyledLink>
          and
          <StyledLink>around the world</StyledLink> healthy, educated and safe.
        </BodyText>
      </BodySection>
    </CardContainer>
  );
};

export const SecondHomeSecondSectionCard = () => {
  const bulletePointsData = [
    {
      text: "$50 - can provide enough food to keep 3 children from going hungry for a month",
    },
    {
      text: "$150 - can wrap warm, cozy blankets around 30 children affected by conflict",
    },
    {
      text: "$300 - can provide a month’s worth of water to 20 children",
    },
  ];
  return (
    <CardContainer bgColor={"#fff"} padding={"2rem 1rem"}>
      <Title titleFontSize={"3.5rem"}>DONATE TODAY: SAVE A LIFE</Title>
      <BodySection>
        <TextWrapper>
          <BodyText textColor>
            <b>Vulnerable children need you more than ever.</b>
          </BodyText>
        </TextWrapper>

        <TextWrapper>
          <BodyText textColor>
            Right now, children are growing up against the backdrop of hunger,
            conflict and natural disasters. Your gift today can help make change
            that protects the lives of children, families and their communities.
          </BodyText>
        </TextWrapper>
        <BulletPointsSection>
          {bulletePointsData.map(({ text }) => (
            <Wrapper textColor>
              <BulletPoints textColor />
              <BulletPointsText textColor>{text}</BulletPointsText>
            </Wrapper>
          ))}
        </BulletPointsSection>
        <BodyText textColor>
          <StyledLink linkColor={"#009ca6"}>
            To give in memory or honor of someone special, click here.
          </StyledLink>
        </BodyText>
      </BodySection>
    </CardContainer>
  );
};
