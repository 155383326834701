import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text,Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";

export const CardContainer = styled(FlexibleDiv)`
  background: ${({ bgColor }) => (bgColor ? bgColor : "#4a4f53")};
  height: max-content;
  padding: ${({ padding }) => (padding ? padding : "2rem 3vw")};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: ${({ bgColor }) => (bgColor ? "100%" : "86%")};
  margin-top: ${({ bgColor }) => (bgColor ? "0" : "2rem")};
  @media (max-width: 1000px) {
    width: ${({ bgColor }) => (bgColor ? "100%" : "95%")};
  }
`;

export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: ${({ titleFontSize }) =>
    titleFontSize ? titleFontSize : "2.2rem"};
  color: ${({ titleFontSize }) =>
    titleFontSize ? "#000" : "#fff"};
`;

export const BodySection = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  gap: 1rem;
`;

export const TextWrapper = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  gap: 0.3rem;
  justify-content: flex-start;

`;

export const BodyText = styled.p`
  font-size: 1.1rem;
  font-weight: ${({ textColor }) => (textColor ? "400" : "600")};
  color: ${({ textColor }) => (textColor ? "#000" : "#fff")};
  word-wrap: wrap;
`;

export const BulletPointsSection = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  padding-left: 2vw;
  flex-direction: column;
  gap:0.3rem;
`;
export const Wrapper = styled(FlexibleDiv)`
  /* background: red; */
  padding: 0;
  justify-content: flex-start;
  align-items: ${({ textColor }) => (textColor ? "flex-start" : "center")};
  gap: 0.4rem;
  
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
export const BulletPoints = styled(FlexibleDiv)`
  background: ${({ textColor }) => (textColor ? "#000" : "#fff")};
  padding: 0.3rem;
  width: max-content;
  border-radius: 50%;
  margin-top: ${({ textColor }) => (textColor ? "0.3rem" : "0px")};
  @media (max-width: 800px) {
    width: max-content;
    margin-top: 0.3rem;
  }
`;
export const BulletPointsText = styled(Text)`
  font-weight: ${({ textColor }) => (textColor ? "400" : "bold")};
  color: ${({ textColor }) => (textColor ? "#000" : "#fff")};
  width:95%;
  font-size: 1.1rem;
  line-height: 1.2rem;
  word-spacing: 0.2rem;
  @media (max-width: 800px) {
    width: 90%;
    font-size: 1.1rem;
    line-height: 1.2rem;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.1rem;
  font-weight: ${({ textColor }) => (textColor ? "400" : "600")};
  color: ${({ linkColor }) => (linkColor ? linkColor : "#ffb300")};
  margin: 0 0.3rem;
  word-wrap: wrap;
  text-decoration: underline;
  /* background:blue; */
`;