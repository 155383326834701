import React from "react";
import {
  HorizontalCardContainer,
  CardImageWrapper,
  CardImage,
  BodyWrapper,
  Description,
  Title,
  BigTitle,StyledLink,
  ButtonWrapper,
  NewsContainer,
  Container,
} from "./horizontalCard.styles";
import { Button } from "../button/button.component";
import CardImage1 from "../../assets/images/horizontal1.jpg";
import CardImage2 from "../../assets/images/horizontal2.jpg";
import CardImage3 from "../../assets/images/horizontal3.jpg";

export const HorizontalCard = ({ image, buttonText, title,description }) => {
  return (
    <HorizontalCardContainer>
      <CardImageWrapper>
        <CardImage src={image} />
      </CardImageWrapper>
      <BodyWrapper>
        <Title>
          {title
            ? title
            : "Join us to learn how you can help make change for children."}
        </Title>
        <Description>
          {description
            ? description
            : "Hundreds of thousands of children who have managed to dodge bullets and bombs are now facing death by starvation and disease."}
        </Description>
        <ButtonWrapper>
          <Button
            fontSize={"20px"}
            btnText={buttonText ? buttonText : "Join Us"}
            background={"#009ca6"}
            width={"100%"}
            paddingVertical={"0.7rem"}
          />
        </ButtonWrapper>
      </BodyWrapper>
    </HorizontalCardContainer>
  );
};

export const News = () => {
  const newsData = [
    {
      image: CardImage1,
      title: "Why Are Children in Gaza and Sudan at Risk of Famine?",
      description:
        "Hundreds of thousands of children who have managed to dodge bullets and bombs are now facing death by starvation and disease.",
      buttonText: "Learn More",
    },
    {
      image: CardImage2,
      title: "10 Heat Wave Safety Tips",
      description:
        "Around the world and in the U.S., millions of children are being impacted by extreme heat. Learn how to keep them safe.",
      buttonText: "Get Tips",
    },
    {
      image: CardImage3,
      title: "3 Million Children in Haiti Need Humanitarian Support",
      description:
        "Children in Haiti are trapped in a deadly cycle of violence, poverty and hunger.",
      buttonText: "Learn More",
    },
  ];

  return (
    <Container>
      <BigTitle>News and Featured Stories.</BigTitle>
      <NewsContainer>
        {newsData.map(({ image, title, buttonText, description }) => (
          <HorizontalCard
            image={image}
            description={description}
            title={title}
            buttonText={buttonText}
          />
        ))}
        <StyledLink>View All News & Stories</StyledLink>
      </NewsContainer>
    </Container>
  );
};

