import React from "react";
import {
  CardContainer,
  Container,
  Title,
  BodySection,
  BodyText,
  BulletPointsSection,
  Wrapper,
  BulletPoints,
  BulletPointsText,
  StyledLink,
  TextWrapper,
} from "./whyWeMatter.styles";

export const WhyWeMatter = () => {
  return (
    <Container>
      <CardContainer>
        <Title>Why What We Do Matters</Title>
        <BodySection>
          <TextWrapper>
            <BodyText>
              We believe every child deserves a future. We know you believe it,
              too.
            </BodyText>
          </TextWrapper>
          <TextWrapper>
            <BodyText>
              Without you, children’s lives and futures will be forever changed
              by the urgent needs they face right now. And we will have failed a
              generation of children. But together, we can achieve tremendous
              results for children. Here's proof:
            </BodyText>
          </TextWrapper>
          <BulletPointsSection>
            <Wrapper>
              <BulletPoints />
              <BulletPointsText>
                <StyledLink> In 2022 alone</StyledLink>
                <b>250,000 children</b>
                in <b>23 U.S. states</b> received over 5 million meals
              </BulletPointsText>
            </Wrapper>
            <Wrapper>
              <BulletPoints />
              <BulletPointsText>
                <b>9 million children</b> accessed quality education – including
                more than <b>4.5 million girls</b>
              </BulletPointsText>
            </Wrapper>
            <Wrapper>
              <BulletPoints />
              <BulletPointsText>
                <b>4.6 million mothers</b>and <b>16 million children</b>{" "}
                improved their nutrition
              </BulletPointsText>
            </Wrapper>
            <Wrapper>
              <BulletPoints />
              <BulletPointsText>
                We helped <b>3.8 million children</b> live a life free from
                violence
              </BulletPointsText>
            </Wrapper>
            <Wrapper>
              <BulletPoints />
              <BulletPointsText>
                Thanks to you, we responded to <b>107 emergencies</b> in more
                than
                <b>70 countries</b>
              </BulletPointsText>
            </Wrapper>
          </BulletPointsSection>
        </BodySection>
      </CardContainer>
    </Container>
  );
};
