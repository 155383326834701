import React from "react";
import { Container } from "./whatWeDo.styles";
import { SecondHeroSection } from "../../components/heroSection/heroSection.component";
import {
  Prenews,
  SecondPrenews,
} from "../../components/fourthVerticalCard/fourthVerticalCard.component";
import { BottomFormSection } from "../../components/bottomFormSection/bottomFormSection.component";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { FulfillingMission, WeDoWhatEverItTakes } from "../../components/fulfillingMission/fulfillingMission.component";
import { WhyWeMatter } from "../../components/whyWeMatter/whyWeMatter.section";
import { ToastContainer } from "react-toastify";

const WhatWeDo = () => {
  return (
    <Container>
      <Header />
      <SecondHeroSection />
      <FulfillingMission />
      <WeDoWhatEverItTakes />
      <Prenews />
      <WhyWeMatter />
      <SecondPrenews />
      <BottomFormSection />
      <Footer />
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default WhatWeDo;
