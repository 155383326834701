import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";
import bgImage from "../../assets/images/background.jpg";

export const Container = styled(FlexibleDiv)`
  background: #fff;
  padding: 2rem
    ${({ paddingHorizontal }) =>
      paddingHorizontal ? paddingHorizontal : "1rem"};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
  width: 86%;
  margin-top: -8vh;
  border-radius: 5px;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;
export const SecondContainer = styled(FlexibleDiv)`
  background: ${({ background }) => (background ? background : "#eeede7")};
  padding: 2rem 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
  width: 86%;
  border-radius: 5px;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;
export const SecondContainerWithBG = styled(FlexibleDiv)`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${bgImage});
  padding: 2rem 7vw;
  background-size: cover;
  background-repeat: round;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
  height: 100vh;
  /* width: 86%; */
  border-radius: 5px;
  @media (max-width: 1000px) {
    width: 95%;
    background-repeat: no-repeat;
  }
`;

export const Wrapper = styled(FlexibleDiv)`
  justify-content: space-between;
  align-items: flex-start;
  /* background: yellow; */
  padding: 0;
  @media (max-width: 800px) {
    gap: 2rem;
  }
`;

export const RightSection = styled(FlexibleDiv)`
  /* background: blue; */
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 50%;
  padding: 0;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const BigTitle = styled(Heading)`
  font-family: "Oswald";
  font-size: ${({ background }) => (background ? "3.8rem" : "2.3rem")};
  line-height: ${({ background }) => (background ? "4rem" : "2.4rem")};
  text-align: center;
  width: 100%;
  color: ${({ background }) => (background ? "#fff" : colors.black.strong)};
`;
export const Styledtext = styled.p`
  color: ${({ background }) => (background ? "#fff" : "#222221")};
  font-size: ${({ background }) => (background ? "1.2rem" : "1.2rem")};
  line-height: ${({ background }) => (background ? "1.5rem" : "1.5rem")};
  margin-top: 0.4rem;
  text-align: center;
`;

export const LinksContainer = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
  margin-top: 1rem;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 850px) {
    justify-content: space-between;
    gap: 2rem 0.5rem;
  }
  @media (max-width: 800px) {
    justify-content: space-between;
    gap: 3rem;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5rem;
  color: #009ca6;
  text-decoration: underline;
  margin: 0 0.3rem;
`;

export const ChartWrapper = styled(FlexibleDiv)`
  width: 45%;
  justify-content: center;
  align-items: flex-start;
  background: yellow;
  padding: 0;

  justify-content: center;
  align-items: center;
  /* background:red; */
  border-radius: 10px;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding: 0;
  padding-bottom: 30.25%;
  height: max-content;
  @media (max-width: 800px) {
    width: 100%;
    padding-bottom: 60.25%;
  }
`;
export const ChartImage = styled.img`
  width: 60%;
  height: auto;
`;
